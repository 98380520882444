import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import Content from "../common/Content";
import MarkdownContent from "../common/MarkdownContent";
import PrettyList from "../common/PrettyList";
import styles from "./Season.module.scss";

class Season extends React.Component {
  render() {
    const { location, siteTitle, season, otherSeasons, services } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={season.frontmatter.title}
          description={`${season.frontmatter.title} tree care services.`}
          keywords={[
            season.frontmatter.title,
            ...services.map((service) => service.frontmatter.title),
          ]}
        />
        <Hero title={season.frontmatter.title} />
        <Content>
          <MarkdownContent html={season.html} />
        </Content>
        {services.length > 0 && (
          <PrettyList
            className={styles.services}
            title={<h2 className="sr-only">Services</h2>}
            items={services.map((service) => ({
              title: service.frontmatter.title || service.fields.slug,
              description: service.frontmatter.description || service.excerpt,
              slug: service.fields.slug,
            }))}
          />
        )}
        <PrettyList
          className={styles.otherSeasons}
          columns={3}
          title={<h2 className="sr-only">Seasons</h2>}
          items={otherSeasons.map((season) => ({
            slug: season.fields.slug,
            imgUrl: season.frontmatter.thumbnail.publicURL,
            title: season.frontmatter.title,
          }))}
        />
      </Layout>
    );
  }
}

Season.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  season: PropTypes.object.isRequired,
  otherSeasons: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
};

export default Season;
