import map from "lodash/map";
import React from "react";
import { graphql } from "gatsby";
import Season from "../components/Season";

class SeasonTemplate extends React.Component {
  render() {
    const {
      location,
      data: { site, season, otherSeasons, services },
    } = this.props;
    const siteTitle = site.siteMetadata.title;

    return (
      <Season
        location={location}
        siteTitle={siteTitle}
        season={season}
        otherSeasons={map(
          otherSeasons && otherSeasons.edges,
          (edge) => edge.node
        )}
        services={map(services && services.edges, (edge) => edge.node)}
      />
    );
  }
}

export default SeasonTemplate;

export const pageQuery = graphql`
  query SeasonBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    season: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        thumbnail {
          publicURL
        }
      }
    }
    otherSeasons: allMarkdownRemark(
      filter: { fields: { slug: { glob: "/seasons/**", ne: $slug } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
    services: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        fields: { slug: { glob: "/services/**" } }
        frontmatter: { seasons: { eq: $slug } }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
